import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import "./form.css";

export const CreateHaikuForm = ({ handleSubmitClick }) => {
  const navigate = useNavigate();

  /* ---------------------------- form- Yup schema ---------------------------- */
  const validationSchema = Yup.object().shape({
    subject: Yup.string()
      .required("Please enter a one word subject.")
      .min(2, "Add between 2 and 15 characters with no spaces")
      .max(15, "Add between 2 and 15 characters with no spaces")
      .matches(
        /^[A-Za-z]+$/,
        "Subject must be a single word using only letters"
      ),
    acceptTerms: Yup.bool().oneOf(
      [true],
      "These terms are required to continue"
    ),
  });

  /* ------------------------------ form - config ----------------------------- */
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  /* -------------------------------- handlers -------------------------------- */
  const onSubmit = (data) => {
    const capitalizedSubject =
      data.subject.charAt(0).toUpperCase() + data.subject.slice(1);
    const terms = data.acceptTerms;

    handleSubmitClick(capitalizedSubject, terms);
  };

  const handleCancelClick = () => {
    reset({ subject: "", acceptTerms: false });
    navigate("/");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form">
      <section className="form__body">
        <label className="form__label">Subject</label>
        <input
          name="subject"
          type="text"
          placeholder="Enter a one-word subject"
          className={` ${errors.subject ? "is-invalid" : ""} form__input`}
          {...register("subject", {
            required: true,
            max: 15,
            min: 2,
            maxLength: 15,
            pattern: /\b[A-Za-z]+\b/i,
          })}
        />

        <div className="form__invalid-feedback">{errors.subject?.message}</div>

        <div className="form__checkbox-container">
          <input
            name="acceptTerms"
            type="checkbox"
            {...register("acceptTerms", { required: true })}
            id="acceptTerms"
            className={`button_type_form-checkbox form-control ${
              errors.acceptTerms ? "is-invalid" : ""
            }`}
          ></input>
          <label className="form__label form__label_type_checkbox">
            I understand that the information returned is generated by an
            experimental artificial intelligence. It may be incorrect,
            incomplete or offensive.
          </label>
        </div>
        <div className="form__invalid-feedback">
          {errors.acceptTerms?.message}
        </div>
      </section>
      <div className="form__button-container">
        <button
          type="button"
          className="button button_type_form button_type_form_cancel"
          onClick={handleCancelClick}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="button button_type_form button_type_form_submit"
        >
          Create
        </button>
      </div>
    </form>
  );
};
