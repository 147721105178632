import React from "react";

export default function Logo({ fill, stroke, ...props }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 238 41.43 "
        {...props}
      >
        <defs>
          <style>{".colorA{fill:rgaba(256,256,256,.975"}</style>
        </defs>

        <g id="font">
          <path
            d="m129.69,31.54h-2.1l-3.1-13.3v13.3h-2V5.54h2v9.53l3.26-9.53h1.77l-3.55,10.44,3.71,15.56Z"
            fill={fill}
            stroke={stroke}
            strokeMiterlimit="10"
          />
          <path
            d="m143.5,32.54h-6v-10h-1v-1h1V6.54h6v1h-4v14h3v2h-3v7h4v2Z"
            fill={fill}
            stroke={stroke}
            strokeMiterlimit="10"
          />
          <path
            d="m158.92,18.54c0,11.79-3.84,13-6.01,13h-1.41V5.54h1.41c2.17,0,6.01,1.21,6.01,13Zm-1.93.48c0-10.45-2.52-11.49-3.49-11.49v22.99c.98,0,3.49-1.04,3.49-11.49Z"
            fill={fill}
            stroke={stroke}
            strokeMiterlimit="10"
          />
          <path
            d="m176.27,19.02c0,11.9-2.68,13.18-4.44,13.18s-4.44-1.28-4.44-13.18,2.68-13.18,4.44-13.18,4.44,1.28,4.44,13.18Zm-1.98,0c0-10.18-1.32-11.66-2.46-11.66s-2.46,1.48-2.46,11.66,1.32,11.66,2.46,11.66,2.46-1.48,2.46-11.66Z"
            fill={fill}
            stroke={stroke}
            strokeMiterlimit="10"
          />
          <path
            d="m193.33,19.02c0,11.9-2.68,13.18-4.44,13.18s-4.44-1.28-4.44-13.18,2.68-13.18,4.44-13.18,4.44,1.28,4.44,13.18Zm-1.98,0c0-10.18-1.32-11.66-2.46-11.66s-2.46,1.48-2.46,11.66,1.32,11.66,2.46,11.66,2.46-1.48,2.46-11.66Z"
            fill={fill}
            stroke={stroke}
            strokeMiterlimit="10"
          />
          <path
            d="m208.92,18.54c0,11.79-3.84,13-6.01,13h-1.41V5.54h1.41c2.17,0,6.01,1.21,6.01,13Zm-1.93.48c0-10.45-2.52-11.49-3.49-11.49v22.99c.98,0,3.49-1.04,3.49-11.49Z"
            fill={fill}
            stroke={stroke}
            strokeMiterlimit="10"
          />
          <path
            d="m224.5,32.54h-6V5.54h2v25h4v2Z"
            fill={fill}
            stroke={stroke}
            strokeMiterlimit="10"
          />
          <path
            d="m237.5,32.54h-6v-10h1v-1h-1V6.54h6v1h-4v14h4v2h-4v7h4v2Z"
            fill={fill}
            stroke={stroke}
            strokeMiterlimit="10"
          />
          <path
            d="m9.5,31.54h-2v-9H3.5v9H1.5v-9H.5v-2h1V5.54h2v15h4V5.54h2v26Z"
            fill={fill}
            stroke={stroke}
            strokeMiterlimit="10"
          />
          <path
            d="m25.24,31.54h-1.95l-.69-5h-3.49l-.65,5h-1.67l3.72-26h1l3.72,26Zm-2.81-7l-1.57-11.83-1.53,11.83h3.1Z"
            fill={fill}
            stroke={stroke}
            strokeMiterlimit="10"
          />
          <path
            d="m34.5,31.54h-2V5.54h2v26Z"
            fill={fill}
            stroke={stroke}
            strokeMiterlimit="10"
          />
          <path
            d="m50.69,31.54h-2.1l-3.1-13.3v13.3h-2V5.54h2v9.53l3.26-9.53h1.77l-3.55,10.44,3.71,15.56Z"
            fill={fill}
            stroke={stroke}
            strokeMiterlimit="10"
          />
          <path
            d="m66.5,26.23c0,4.15-1.71,5.49-3.52,5.49s-3.48-1.35-3.48-5.49V5.54h2v21.3c-.66,2.53.34,3.34,1.03,3.34s1.72-.81,1.97-3.34V5.54h2v20.7Z"
            fill={fill}
            stroke={stroke}
            strokeMiterlimit="10"
          />
        </g>
        <g id="flower2">
          <path
            d="m110.5,14.11c-2.51-2.11-4.58-3.76-2.87-7.39.35-.73-.39-1.97-.73-3.21-2.2-3.77-3.92-4.31-7.54-2.55-3.11,1.51-5.3,3.82-5.92,7.97-1.44-1.85-2.27-3.05-3.23-4.13-2.56-2.88-6.03-3.73-8.67-2.22-3.1,1.77-4.34,5.54-2.93,9.27.47,1.25,1.07,2.51,1.85,3.58.88,1.21.86,1.75-.56,2.56-2.59,1.47-4.09,3.77-4.2,6.87-.14,3.96,1.41,5.34,5.32,4.67q3.45-.59,2.8,2.86c-.78,4.09,1.82,8.18,5.69,8.95,2.63.52,4.57-1.43,5.73-5.78.05-.2.08-.41.12-.61.33-1.62.89-2.27,2.4-.84,2.15,2.04,4.77,2.32,7.22.89,2.46-1.43,2.89-3.99,2.23-6.61-.47-1.84.1-2.72,1.76-3.67,3.48-2,4.47-8.13,1.53-10.61Zm-12.19,6.74c-.32,2.14-3.8,4.6-5.92,4.31-2.2-.3-3.7-1.62-3.87-3.86-.2-2.64.82-4.73,3.63-5.42.99-.24,2.06-.2,3.33-.25,3.2.71,3.16,3.01,2.83,5.22Z"
            fill="#f7d3eb"
          />
        </g>
      </svg>
    </>
  );
}
