import React from "react";

import "./yinyang.css";
import Lobe from "./Lobe";
import Circle from "./Circle";
import TextLeft from "./TextLeft";
import TextRight from "./TextRight";
import IconLink from "../iconLink";

export default function Yinyang({ onLeftClick,onRightClick,rightHref,leftHref, ...props }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 680.15 680.15"
        {...props}
      >
        <defs>
          <style>
            {".colorA{fill:#2b2d42}"}
            {".colorB{fill:rgba(256,256,256,.975)}"}
            {".colorC{fill-opacity:0; fill:#000}"}
            {".text{font-family:Montserrat,Arial;font-size:48px}"}
          </style>
        </defs>

        <IconLink onClick={onRightClick}href={rightHref}>
          <g id="yy-right">
            <Lobe
              id="lobe-right"
              classname="colorB"
              d="M671.57 264.14c-9.83-42.85-27.66-82.91-52.99-119.09-25.33-36.17-56.88-66.63-93.79-90.52C486.6 29.79 444.3 13.21 399.1 5.24s-90.62-6.85-134.97 3.33h.02c-42.85 9.83-82.91 27.66-119.09 52.99-36.17 25.33-66.63 56.88-90.52 93.79-20.02 30.9-34.69 64.49-43.78 100.13 26.59-78.33 107.04-127.72 191.02-112.92 92.73 16.35 154.65 104.79 138.3 197.52-16.35 92.73 45.57 181.17 138.3 197.52 83.97 14.81 164.43-34.58 191.02-112.92.64-2.47 1.24-4.95 1.81-7.43.02-.05.03-.09.03-.14 1.19-5.13 2.26-10.3 3.21-15.52.16-.83.31-1.65.46-2.48s.28-1.66.42-2.49c.89-5.22 1.65-10.45 2.29-15.68 0-.05.02-.09.01-.14.31-2.52.6-5.06.84-7.6 3.65-36.6 1.35-73.18-6.9-109.06Zm-130 111.46c-3.27 18.54-20.96 30.93-39.5 27.66-18.54-3.27-30.93-20.96-27.66-39.5 3.27-18.54 20.96-30.93 39.5-27.66 18.54 3.27 30.93 20.96 27.66 39.5Z"
            />

            <Circle
              id="circle-right"
              cx="509.7"
              cy="371.5"
              r="35.5"
              classname="colorA "
            />
            <TextRight classname="colorA text " id="text-right" />
          </g>
          <g id="rightmask">
            <Lobe
              classname="colorC"
              d="M678.47 373.2c-.24 2.54-.53 5.08-.84 7.6.01.05-.01.09-.01.14-.64 5.23-1.4 10.46-2.29 15.68-.14.83-.27 1.66-.42 2.49-.15.83-.3 1.65-.46 2.48-.95 5.22-2.02 10.39-3.21 15.52 0 .05-.01.09-.03.14-.57 2.47-1.17 4.95-1.8 7.41v.02a169.292 169.292 0 0 1-17.34 36.26c-2.38 3.74-4.89 7.38-7.52 10.9-1.32 1.75-2.66 3.48-4.04 5.17-1.39 1.71-2.81 3.39-4.25 5.04-1.08 1.23-2.17 2.45-3.29 3.64-1.11 1.2-2.24 2.38-3.39 3.54-1.47 1.49-2.97 2.96-4.49 4.4-1.53 1.44-3.08 2.84-4.65 4.22-4.42 3.87-9.02 7.51-13.81 10.9-4.31 3.06-8.76 5.92-13.34 8.56 0 0 0 .01-.01.01-4.82 2.79-9.79 5.35-14.88 7.66 0 0 0 .01-.01.01-1.27.57-2.55 1.13-3.83 1.68-.64.28-1.29.55-1.95.82-28.83 11.84-61.25 15.92-94.22 10.11-35.99-6.35-67.35-23.56-91.27-47.66-18.62-18.75-32.75-41.66-41.08-66.88-8.56-25.91-11.02-54.25-5.95-83s2.62-57.08-5.95-82.98a171.392 171.392 0 0 0-41.08-66.88c-23.93-24.09-55.28-41.3-91.27-47.64-74.73-13.17-146.67 24.49-180.35 88.15-2.07 3.91-3.99 7.93-5.77 12.03-.02.06-.05.12-.08.18-1.76 4.1-3.37 8.29-4.82 12.56v-.02s.01-.04.02-.06c9.09-35.61 23.75-69.17 43.76-100.05 23.89-36.91 54.35-68.46 90.52-93.79 36.18-25.33 76.24-43.16 119.09-52.99 44.34-10.18 89.76-11.3 134.95-3.33 45.2 7.97 87.5 24.55 125.69 49.29 36.91 23.89 68.46 54.35 93.79 90.52 25.33 36.18 43.16 76.24 52.99 119.09 8.25 35.88 10.55 72.46 6.9 109.06Z"
            />
          </g>
        </IconLink>

        <IconLink onClick={onLeftClick}href={leftHref}>
          <g id="yy-left">
            <Lobe
              classname="colorA"
              d="M478.38 537.58c-92.73-16.35-154.65-104.79-138.3-197.52s-45.57-181.17-138.3-197.52c-83.97-14.81-164.43 34.58-191.02 112.92-.65 2.52-1.26 5.04-1.84 7.57-1.19 5.13-2.26 10.3-3.21 15.52-.15.83-.31 1.65-.46 2.48s-.28 1.66-.42 2.49c-.89 5.22-1.65 10.45-2.29 15.68-.32 2.57-.6 5.15-.86 7.74-3.65 36.6-1.35 73.18 6.9 109.06 9.83 42.85 27.66 82.91 52.99 119.09 25.33 36.17 56.88 66.63 93.79 90.52 38.19 24.74 80.49 41.32 125.69 49.29s90.62 6.85 134.97-3.33c42.85-9.83 82.91-27.66 119.09-52.99 36.17-25.33 66.63-56.88 90.52-93.79 20.02-30.9 34.69-64.48 43.78-100.13-26.59 78.33-107.04 127.72-191.02 112.92h-.01ZM138.59 304.53c3.27-18.54 20.96-30.93 39.5-27.66s30.93 20.96 27.66 39.5c-3.27 18.54-20.96 30.93-39.5 27.66s-30.93-20.96-27.66-39.5Z"
            />

            <Circle
              id="lightcircle"
              cx="171.27"
              cy="309.8"
              r="35.5"
              classname="colorB"
            />

            <TextLeft classname="colorB text" id="text-left" />
          </g>
          <g id="leftmask">
            <Lobe
              classname="colorC"
              d="M669.4 424.68c-9.09 35.64-23.75 69.22-43.77 100.11-23.89 36.91-54.35 68.46-90.52 93.79-36.18 25.33-76.24 43.16-119.09 52.99-44.35 10.18-89.77 11.3-134.97 3.33-45.2-7.97-87.5-24.55-125.69-49.29-36.91-23.89-68.46-54.35-93.79-90.52C36.24 498.91 18.41 458.85 8.58 416c-8.25-35.88-10.55-72.46-6.9-109.06.26-2.59.54-5.17.86-7.74.64-5.23 1.4-10.46 2.29-15.68.14-.83.27-1.66.42-2.49.15-.83.31-1.65.46-2.48.95-5.22 2.02-10.39 3.21-15.52.58-2.52 1.19-5.04 1.84-7.55 1.45-4.27 3.06-8.46 4.82-12.56.03-.06.06-.12.08-.18 1.78-4.1 3.7-8.12 5.77-12.03 33.68-63.66 105.62-101.32 180.35-88.15 35.99 6.34 67.34 23.55 91.27 47.64a171.392 171.392 0 0 1 41.08 66.88c8.57 25.9 11.02 54.23 5.95 82.98s-2.61 57.09 5.95 83c8.33 25.22 22.46 48.13 41.08 66.88 23.92 24.1 55.28 41.31 91.27 47.66 32.97 5.81 65.39 1.73 94.22-10.11.66-.27 1.31-.54 1.95-.82 1.29-.54 2.56-1.1 3.83-1.68.01 0 .01-.01.01-.01 5.09-2.31 10.06-4.87 14.88-7.66.01 0 .01-.01.01-.01 4.58-2.64 9.03-5.5 13.34-8.56 4.79-3.39 9.39-7.03 13.81-10.9 1.57-1.38 3.12-2.78 4.65-4.22 1.52-1.44 3.02-2.91 4.49-4.4 1.15-1.16 2.28-2.34 3.39-3.54 1.12-1.19 2.21-2.41 3.29-3.64 1.44-1.65 2.86-3.33 4.25-5.04 1.38-1.69 2.72-3.42 4.04-5.17 2.63-3.52 5.14-7.16 7.52-10.9a169.292 169.292 0 0 0 17.34-36.26Z"
            />
          </g>
        </IconLink>
      </svg>
    </>
  );
}
